/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "./src/global-styles/cookie-banner-overrides.css"
import "./src/global-styles/cookieconsent.min.css"
import "./src/global-styles/goodhire-core-patterns.min.css"
import "./src/global-styles/job-post.css"
import "./src/global-styles/style-overrides.css"
import { StoreProvider } from "./src/store/index"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)
