import { setAnnouncementState } from "./announcement"
import { setMobileMenuState, setMobileSubState } from "./mobileMenuState"
import { setViewPortWidth } from "./windowDimensions"

const storeMethods = {
  setViewPortWidth,
  setMobileMenuState,
  setMobileSubState,
  setAnnouncementState,
}

export default storeMethods
