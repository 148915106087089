export function setMobileMenuState() {
  this.setState({
    isMobileMenuActive: !this.state.isMobileMenuActive,
  })
}

export function setMobileSubState(state) {
  this.setState({
    isMobileSubActive: state,
  })
}
